<template>
  <b-card no-body>

    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Batch</label>
          <v-select placeholder="Select batch"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            multiple
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Course</label>
          <v-select placeholder="Select course"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="planFilter"
            :options="planOptions"
            multiple
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select placeholder="Select status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="placedFilter"
            :options="placedOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:placedFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { watch } from "@vue/composition-api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  
  // watch : {
  //   roleOptions : function () {
  //     console.log('roleOptions changed');
  //   }
  // },
  props: {
    roleFilter: {
      type: [Array, null],
      default: null,
    },
    planFilter: {
      type: [Array, null],
      default: null,
    },
    placedFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    placedOptions: {
      type: Array,
      required: true,
    },
  },

  watch: {
    roleFilter (val){
      console.log(val)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  input {
    &::placeholder {
      color: #043c57;
      opacity: 0.4;
      font-size: 16px;
    }
  }
}
</style>
