<template>
  <div>
    
    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :placed-filter.sync="placedFilter"
      :role-options="batchOptions"
      :plan-options="courseOptions"
      :placed-options="placedOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 student-header-box">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center align-middle justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              > -->
              <b-button variant="primary" @click="DownloadCSV">
                <span class="text-nowrap">Download</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative students-list-table"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(FullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                square
                variant="transparent"
                class="text-secondary"
                :src="data.item.profilephoto"
                :text="avatarText(data.item.FullName)"
                :to="{
                  name: 'student-profile',
                  params: { studentId: data.item.id },
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'student-profile',
                params: { studentId: data.item.id },
              }"
              class=" d-block text-nowrap text-capitalize studentname"
              style="font-weight:600;color:#043c57"
            >
              {{ data.item.FullName }}
            </b-link>
            <small >{{ data.item.email }}</small>
          </b-media>
        </template>
        
        <!-- Column: Role -->
        <template #cell(verified)="data">
          <div class="text-nowrap" >
            <u-icon
              icon="fi-rr-ban"
              v-if="data.item.isBlocked"
              size="18"
              class="align-middle mr-50 text-danger"
            />
            <u-icon
              icon="fi-rr-shield-check"
              v-else-if="data.item.verified"
              size="18"
              class="align-middle mr-50 text-success"
            />
            <u-icon
              icon="fi-rr-shield-exclamation"
              v-else
              size="18"
              class="align-middle mr-50 text-secondary"
            />

            <span
              class="align-text-bottom text-capitalize"
              :class="{
                'text-danger': data.item.isBlocked,
                'text-success': data.item.verified,
              }"
            >
              {{
                data.item.isBlocked
                  ? "Blocked"
                  : data.item.verified == true
                  ? "verified"
                  : "Un-verified"
              }}</span
            >
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(placed)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserRoleVariant(data.item.placed)}`"
            class="text-capitalize"
          >
            {{ data.item.placed }}
          </b-badge>
          <!-- <b-badge v-if="data.item.isBlocked"
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            Blocked
          </b-badge> -->
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'student-profile',
                params: { studentId: data.item.id },
              }"
            >
              <!-- <feather-icon icon="FileTextIcon" /> -->
              <u-icon icon="fi-rr-document" class="align-middle" />
              <span class="align-text-bottom ml-50">Details</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              :to="{ name: 'apps-users-edit', params: { studentId: data.item.id } }"
            > -->
            <b-dropdown-item
              v-if="!data.item.verified"
              @click="verify(data.item.id, data.item.FullName)"
            >
              <u-icon icon="fi-rr-shield-check" class="align-middle" />
              <span class="align-text-bottom ml-50">Verify</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              @click="unverify(data.item.id, data.item.FullName)"
            >
              <u-icon icon="fi-rr-shield-exclamation" class="align-middle" />
              <span class="align-text-bottom ml-50">Unverify</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!data.item.isBlocked"
              @click="blockStudent(data.item.id, data.item.FullName)"
            >
              <u-icon icon="fi-rr-ban" class="align-middle" />
              <span class="align-text-bottom ml-50">Block</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              @click="unblockStudent(data.item.id, data.item.FullName)"
            >
              <u-icon icon="fi-rr-ban" class="align-middle" />
              <span class="align-text-bottom ml-50">Unblock</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import rawUserList from "./useUsersList";
import userStoreModule from "./userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import firebase from "firebase/app";

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  created() {
    //changing when store value changed
    this.$store.watch(
      (state) => {
        return this.$store.state.mainUserCodes;
      },
      (newVal, oldVal) => {
        this.fetchUsers;
      },
      { deep: true }
    );
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const placedOptions = [
      { label: "Placed", value: "Placed" },
      { label: "Not Placed", value: "Not Placed" },
      // { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      isValueUpdated,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      rawList,
      filteredList,

      // Extra Filters
      roleFilter,
      planFilter,
      placedFilter,
    } = useUsersList();

    const batchOptions = ref([]);
    const rawbatch = [];
    const courseOptions = ref([]);
    const coursebatch = [];

    // const { rawList } = rawUserList();
    watch([rawList], () => {
      if (rawList.value.length > 1) {
        rawList.value.forEach((item) => {
          if (rawbatch.includes(item.batch) == false) {
            rawbatch.push(item.batch);
            batchOptions.value.push({ label: item.batch, value: item.batch });
            localStorage.setItem('batchOptions', rawbatch)
          }

          if (coursebatch.includes(item.courseName) == false) {
            coursebatch.push(item.courseName);
            courseOptions.value.push({
              label: item.courseName,
              value: item.courseName,
            });
          }
        });
      }
    });
    function arraytocsv(data) {
      const csvString = [
        [
          "Name",
          "email",
          "Course",
          "Batch",
          "Jobs Applied",
          "Status",
          "Verified",
        ],
        ...data.map((item) => [
          `"${item.FullName}"`,
          `"${item.email}"`,
          `"${item.courseName}"`,
          `"${item.batch}"`,
          `"${item.appliedIn}"`,
          `"${item.placed}"`,
          `"${item.verified}"`,
        ]),
      ]
        .map((e) => e.join(","))
        .join("\n");

      console.log(csvString);
      return csvString;
      //   return data.map(row =>{

      //     console.log(row)
      //     row
      //     // .map(String)  // convert every value to String
      //     .map(v => v.replaceAll('"', '""'))  // escape double colons
      //     .map(v => `"${v}"`)  // quote it
      //     .join(',')  // comma-separated
      //  } ).join('\r\n')
    }

    function downloadBlob(content, filename, contentType) {
      // Create a blob
      var blob = new Blob([content], { type: contentType });
      var url = URL.createObjectURL(blob);

      // Create a link to download it
      var pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", filename);
      pom.click();
    }
    function DownloadCSV() {
      let data = arraytocsv(filteredList.value);

      downloadBlob(data, "Students.csv", "text/csv;charset=utf-8;");
    }

    function verifyProfile() {}

    //  watch(roleFilter, (currentValue, oldValue) => {
    //   console.log(currentValue);

    //   console.log()
    //   console.log(oldValue);
    // });

    // const { rawList } = rawUserList();

    // fetchUsers(val, userVal);
    // fetchUsers.forEach((item) => {
    //   if(!rawbatch.includes(item.batch)){
    //     rawbatch.push(item.batch)
    //     batchOptions.push({label : item.batch, value: item.batch})
    //   }
    // })
    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      isValueUpdated,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      placedOptions,
      batchOptions,
      courseOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      placedFilter,
      DownloadCSV,
    };
  },

  methods: {
    rowClass(item, type) {
      if (typeof item !== null)
        if (item.isBlocked == true) return "text-danger";
    },

    verify(uid, name) {
      this.$swal({
        title: `Verify ${name}`,
        text: "Are you sure, you want to verify this student profile? ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, verify it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log(uid);
          firebase
            .firestore()
            .collection("StudentID")
            .doc(uid)
            .set(
              {
                verified: true,
              },
              { merge: true }
            )
            .then((val) => {
              // this.refetchData("firebase")
              this.isValueUpdated = true;
              console.log("done");
            });
          this.$swal({
            icon: "success",
            title: "Verified!",
            text: `${name} has been verified.`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    unverify(uid, name) {
      this.$swal({
        title: `Unverify ${name}`,
        text: "Do You want to remove verification of this profile ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, unverify it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log(uid);
          firebase
            .firestore()
            .collection("StudentID")
            .doc(uid)
            .set(
              {
                verified: false,
              },
              { merge: true }
            )
            .then((val) => {
              // this.refetchData("firebase")
              this.isValueUpdated = true;
              this.$swal({
                icon: "success",
                title: "Unverified!",
                text: `${name} has been unverified.`,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    blockStudent(uid, name) {
      let tpoid = store.state.mainUserCodes.user_uid;
      this.$swal({
        title: `Block ${name}`,
        text: "Do You want to block this profile ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, block it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          firebase
            .firestore()
            .collection("StudentID")
            .doc(uid)
            .set(
              {
                isBlocked: true,
                blockedBy: firebase.firestore.FieldValue.arrayUnion(tpoid),
              },
              { merge: true }
            )
            .then(() => {
              this.isValueUpdated = true;

              this.$swal({
                icon: "success",
                title: "Blocked!",
                text: `${name} has been blocked.`,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    unblockStudent(uid, name) {
      let tpoid = store.state.mainUserCodes.user_uid;
      this.$swal({
        title: `Unblock ${name}`,
        text: "Do You want to unblock this profile ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, unblock it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          firebase
            .firestore()
            .collection("StudentID")
            .doc(uid)
            .set(
              {
                isBlocked: false,
                blockedBy: firebase.firestore.FieldValue.arrayRemove(tpoid),
              },
              { merge: true }
            )
            .then(() => {
              this.isValueUpdated = true;

              this.$swal({
                icon: "success",
                title: "Unblocked!",
                text: `${name} has been unblocked.`,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
  //height:28px;
  font-size:14px;
  border-radius:8px;
}
.studentname{
  &:hover {
    color: #039be5 !important;
          transition: all 0.25s ease;

    }
}
.vs__dropdown-toggle{
    border-radius:8px;

}
.student-header-box {
  .form-control {
    height: 38px;
    border-radius: 12px;
  }
  .per-page-selector {
  }
}
.students-list-table ::v-deep td:nth-child(4) {
  text-align: center;
}
// .students-list-table ::v-deep td:nth-child(5) {
//   text-align: center;
// }
// .students-list-table ::v-deep td:nth-child(6) {
//   text-align: center;
// }
// .students-list-table ::v-deep th:nth-child(4) {
//   text-align: center;
// }
// .students-list-table ::v-deep th:nth-child(5) {
//   text-align: center;
// }
// .students-list-table ::v-deep th:nth-child(6) {
//   text-align: center;
// }
.students-list-table ::v-deep thead {
  tr:first-child{
    th{
      border-top: 1px solid #efefef; 
    }
  }
    tr:first-child {
      th:first-child {
        border-top-left-radius: 0px;
      }
      th:last-child {
        border-top-right-radius: 0px;
      }
    }
  }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
