import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { paginateArray, sortCompare } from "@/@fake-db/utils";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BJumbotron } from "bootstrap-vue";
// import { is } from "core-js/core/object";

const getFirebaseData = async () => {
  let productsList = [];
  // console.log(store.state);
  var collegeCode = store.state.mainUserCodes.StudentCode;

  let result = await new Promise((resolve, reject) => {
    let studRef = firebase
      .firestore()
      .collection("StudentID")
      .orderBy("timestamp", "desc")
      .where("StudentCode", "==", collegeCode)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          var obj = doc.data();
          let placed = doc.data().PlacedIn ?? [];
          let courseName = doc.data().courseName ?? "";
          obj.id = doc.id;
          

          obj.placed = placed.length > 0 ? "Placed" : "Not Placed";
          obj.verified = doc.data().verified || false;
          obj.isBlocked = doc.data().isBlocked || false;
          obj.courseName = courseName;
          obj.FullName = doc.data().FullName ?? "";
          

          firebase
            .firestore()
            .collection("StudentID")
            .doc(doc.id)
            .collection("EducationDetails")
            .doc(doc.id)
            .get()
            .then(async (eDdoc) => {
              // console.log(doc.data());

              
              if (eDdoc.exists ) {
                let doclength = Object.keys(eDdoc.data()).length;
               if( doclength > 0) {
                // console.log(eDdoc.id);
                // console.log(sddj);

                if (eDdoc.data().newData) {
                  if (typeof eDdoc.data().grad_date !== "undefined") {
                    obj.batch = eDdoc
                      .data()
                      .grad_date.toDate()
                      .getFullYear();
                    // obj.batch =
                    //   eDdoc
                    //     .data()
                    //     .AdmDate.toDate()
                    //     .getFullYear() +
                    //   " - " +
                    //   eDdoc
                    //     .data()
                    //     .grad_date.toDate()
                    //     .getFullYear();
                  } else obj.batch = "-";
                } else {
                  if (typeof eDdoc.data().grad_date != "undefined") {
                    obj.batch = new Date(eDdoc.data().grad_date).getFullYear();

                    // obj.batch =
                    //   new Date(eDdoc.data().AdmDate).getFullYear() +
                    //   " - " +
                    //   new Date(eDdoc.data().grad_date).getFullYear();
                  } else obj.batch = "-";
                }
                obj.newData = eDdoc.data().newData || false;
           }   } else {
                obj.batch = "-";
              }

              if (typeof doc.data().AppliedIn !== "undefined") {
                obj.appliedIn = doc.data().AppliedIn.length;
              } else {
                obj.appliedIn = 0;
              }
              // console.log(obj);
              productsList.push(obj);
            })
            .then(() => {
              // console.log(productsList.length);
              // return productsList
              if (productsList.length == docs.size) {
                resolve(productsList);
              }
            });
          // .catch((err) => {
          //   console.log(err);
          // });
        });
        // .then(() => {

        // });
      });

    // for(doc of studRef.docs){
    //   var obj = doc.data();
    //   let placed = doc.data().PlacedIn ?? [];
    //   let courseName = doc.data().courseName ?? "";
    //   obj.id = doc.id;

    //       obj.placed = placed > 0 ? "Placed" : "Not Placed";
    //       obj.verified = doc.data().verified || false;
    //       obj.courseName = courseName;
    //       obj.FullName = doc.data().FullName ?? "";
    //   await firebase
    //   .firestore()
    //   .collection("StudentID")
    //   .doc(doc.id)
    //   .collection("EducationDetails")
    //   .doc(doc.id)
    //   .get()
    //   .then(async (eDdoc) => {
    //     // console.log(doc.data());

    //     let doclength = Object.keys(eDdoc.data()).length;
    //     if (eDdoc.exists && doclength > 0) {
    //       // console.log(eDdoc.id);
    //       // console.log(sddj);

    //       if (eDdoc.data().newData) {
    //         if (typeof eDdoc.data().grad_date !== "undefined") {
    //           obj.batch = eDdoc
    //             .data()
    //             .grad_date.toDate()
    //             .getFullYear();
    //           // obj.batch =
    //           //   eDdoc
    //           //     .data()
    //           //     .AdmDate.toDate()
    //           //     .getFullYear() +
    //           //   " - " +
    //           //   eDdoc
    //           //     .data()
    //           //     .grad_date.toDate()
    //           //     .getFullYear();
    //         } else obj.batch = "-";
    //       } else {
    //         if (typeof eDdoc.data().grad_date != "undefined") {
    //           obj.batch = new Date(eDdoc.data().grad_date).getFullYear();

    //           // obj.batch =
    //           //   new Date(eDdoc.data().AdmDate).getFullYear() +
    //           //   " - " +
    //           //   new Date(eDdoc.data().grad_date).getFullYear();
    //         } else obj.batch = "-";
    //       }
    //       obj.newData = eDdoc.data().newData || false;
    //     } else {
    //       obj.batch = "-";
    //     }

    //     if (typeof doc.data().AppliedIn !== "undefined") {
    //       obj.appliedIn = doc.data().AppliedIn.length;
    //     } else {
    //       obj.appliedIn = 0;
    //     }
    //     // console.log(obj);
    //     productsList.push(obj);
    //   })
    //   .then(() => {
    //     // console.log(productsList.length);
    //     // return productsList
    //     if (productsList.length == docs.size) {
    //       resolve(productsList);
    //     }
    //   });

    // }
  });
  // let result = await firebase
  //       .firestore()
  //       .collection("StudentID").orderBy('timestamp', 'desc').where('StudentCode','==', collegeCode).get().then(async (docs) => {
  //           // console.log(docs.size)
  //          await docs.forEach(async(doc) => {
  //       await firebase
  //       .firestore()
  //       .collection("StudentID").doc(doc.id).collection("EducationDetails").doc(doc.id).get().then(async (eDdoc) => {
  //         console.log(eDdoc.data());
  //         var obj = doc.data()
  //               obj.id = doc.id
  //               obj.verified = doc.data().verified || false
  //               if(typeof (doc.data().AppliedIn) !== 'undefined'){
  //               obj.appliedIn = doc.data().AppliedIn.length

  //             }else { obj.appliedIn = 0 }
  //               console.log(obj);
  //            await productsList.push(obj)

  //       }).catch((err)=>{
  //         console.log(err);
  //       })

  //           } ).then(() => {
  //             console.log(productsList.length);
  //         return productsList
  //           })
  //       }).then(() => {
  //         // console.log(productsList.length);
  //         // return productsList
  //       });
  //  var promiseRes = await result.then((value) => {
  //   return value
  // })
  // return promiseRes;
  return result;
};

let users = [];
let filteredData = [];
const getDataandSort = async (config) => {
  // console.log(config);
  if (users.length < 1 || config.isValueUpdated == true) {
    // console.log("this is users length");
    // console.log(users.length);
    users = await getFirebaseData();
  }
  // console.log('hi this users');
  // console.log(users.length);
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
    role = [],
    plan = [],
    placed = null,
  } = config;
  // let users2 = users
  //   if(config.role ){
  //    users2 = users.filter(item =>{

  //     if(item.batch === config.role)  return item
  //   })
  //   // console.log(users2)
  // }
  // else if(config.plan ){
  //   users2 = users.filter(item =>{

  //    if(item.courseName === config.plan)  return item
  //  })
  // //  console.log(users2)
  // }
  // console.log(config.plan)
  // console.log(config.role && config.plan)

  // if(config.role && config.plan){
  //   users2 = users.filter(item =>{

  //     if(item.batch === config.role && item.courseName === config.plan)  return item
  //   })
  //   // console.log(users2)
  //  }

  const queryLowered = q.toLowerCase();
  // const filteredData = users;
  // console.log(filteredData.length);

  let user = users.filter(
    (user) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      // user.batch.toLowerCase().includes(queryLowered) ||
      // user.courseName.toLowerCase().includes(queryLowered) ||
      user.FullName.toLowerCase().includes(queryLowered) &&
      // user.batch === (role || user.batch) &&
      // user.courseName === (plan || user.courseName) &&
      user.placed === (placed || user.placed)
  );

  if (role.length > 0) {
    let arr = [];
    user.forEach((usr) => {
      role.forEach((elem) => {
        if (usr.batch === elem) arr.push(usr);
      });
    });
    user = arr;
  }

  if (plan.length > 0) {
    let arr2 = [];
    user.forEach((usr) => {
      plan.forEach((elem) => {
        if (usr.courseName === elem) arr2.push(usr);
      });
    });

    // console.log(arr2);
    user = arr2;
  }
  const sortedData = user.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();
  // console.log(sortedData.length);
  filteredData = sortedData;
  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: sortedData.length,
      rawUsers: users,
    },
  ];
};

export function rawUserList() {
  const rawList = ref([]);

  rawList.value = users;

  return { rawList };
}
// export function filterdUserList() {
//   const filteredList=ref([]);

//   filteredList.value= filteredData;

//   return { filteredList};
// }
export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "FullName",
      label: "Student Name",
      formatter: title,
      sortable: true,
    },
    {
      key: "courseName",
      label: "Course",
      formatter: title,
      sortable: true,
    },
    {
      key: "batch",
      label: "Batch",
      formatter: title,
      sortable: true,
    },
    {
      key: "appliedIn",
      label: "Jobs Applied",
      formatter: title,
      sortable: true,
    },
    {
      key: "placed",
      label: "Status",
      formatter: title,
      sortable: true,
    },

    // { key: 'user', sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'role', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: "verified", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const placedFilter = ref(null);
  const rawList = ref([]);
  const filteredList = ref([]);
  const isValueUpdated = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = (val) => {
    // console.log("refetching....");
    refUserListTable.value.refresh();
    // console.log(refUserListTable)
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      planFilter,
      placedFilter,
      isValueUpdated,
    ],
    () => {
      refetchData();
    }
  );

  const fetchUsers = (ctx, callback) => {
    // store
    //   .dispatch('app-user/fetchUsers', {
    //     q: searchQuery.value,
    //     perPage: perPage.value,
    //     page: currentPage.value,
    //     sortBy: sortBy.value,
    //     sortDesc: isSortDirDesc.value,
    //     role: roleFilter.value,
    //     plan: planFilter.value,
    //     placed: placedFilter.value,
    //   })
    // console.log(`this searchQuery obj ${searchQuery.value.length}`);
    getDataandSort({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      isValueUpdated: isValueUpdated.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role: roleFilter.value ?? [],
      plan: planFilter.value ?? [],
      placed: placedFilter.value,
    })
      .then((response) => {
        const { users, total, rawUsers } = response[1];
        // console.log(rawUsers);
        // console.log('here we have raw users');
        rawList.value = rawUsers;
        filteredList.value = filteredData;
        callback(users);
        totalUsers.value = total;
        isValueUpdated.value = false;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "Placed") return "success";
    // if (role === "author") return "warning";
    // if (role === "maintainer") return "success";
    // if (role === "editor") return "info";
    // if (role === "admin") return "danger";
    return "secondary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === true) return "success";
    if (status === false) return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    isValueUpdated,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    rawList,
    filteredList,

    // Extra Filters
    roleFilter,
    planFilter,
    placedFilter,
  };
}